.main-body{
    /* background-color: black; */
    
}

.main-contaner{
    margin-top: 3%;
    /* background-color: black; */
}

.sec-1{
    margin-top: 5%;
    
}
.pic{
    height: 80vh
}
.sec-1{
    color: white;
}
.sec-hi{
    margin-bottom: 15%;
    font-size: 5rem;
    /* font-weight: 800; */
}

.sec-body{
    margin-left: 6%;
    line-height: 150%;
    /* font-weight: 800; */
    background-color: rgba(255, 255, 255, 0.055);
    
}

.sec-social{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10%;
    font-weight: 800;
}

.sec-bottom{
    margin-top: 25%;
    text-align: right;
    
}

@media screen and (max-width: 800px){
    .sec-2{
        display: none;
    }
    .sec-1{
        text-align: center;
        width: 100%;
    }
   .sec-body{
        min-width: 100%;
        margin-left: 0%
        
    }
    .sec-bottom{
        text-align: center;
        width: 100%;
       
    }
    
}

