.skills{
  
    padding-top: 10%;
}
.skill-header{
    text-align: center;
    margin-right: 5%;
    color: white;
    font-weight: 800;
    
    
}

.skill-header > h1{
    text-align: center;
    
    font-weight: 800;
    
    
}
.skill-name{
    margin-top: 5%;
    
    justify-content: space-between;
    width: 100%;
}
.front-end{
    width: 100%;
    padding-bottom: 3%;
    border-bottom: 1px solid rgba(255,255,255,0.96);
    font-weight: 800;
}
.front-end > h2{
    width: 100%;
    padding-bottom: 3%;
    border-bottom: 1px solid rgba(255,255,255,0.96);
    font-weight: 700;
}
.back-end{
    width: 100%;
    margin-top: 5%;
    padding-bottom: 3%;
    border-bottom: 1px solid rgba(255,255,255,0.96);
}
.front-end-icons{
    
    display: flex;
    justify-content: space-evenly;
}
.back-end-icons{
    
    display: flex;
    justify-content: space-evenly;
}