.project-header {
  text-align: center;
  color: white;
}
.my-project {
  /* background-color: black;
      */
  margin-top: 5%;
}

.projects-flex1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.p1-body {
  height: 300px;
}
@media (max-width: 1200px) {
  .p1-body {
    height: 350px;
  }
  .projects-flex1 {
    /* text-align: center; */
    flex-direction: column;
    padding: 0px;
    align-items: center;
    /* margin: auto; */
  }

  div .p1-image {
    width: 300px;
    height: 80%;
  }

  div .p1-body {
    width: 300px;
  }
}
@media (max-width: 480px) {
  div .projects-flex1 {
    /* text-align: center; */
    align-items: flex-start;
    padding-left: 10%;
    /* margin: auto; */
  }
}
.p1 {
  margin-top: 5%;
  width: 48%;
}

.p1-body {
  color: white;
  width: 480px;
  background-color: rgba(3, 3, 3, 0.357);
  padding-left: 2%;
}

.p1-title {
  padding-top: 3%;
  font-weight: 800;
}
.p1-info {
  font-size: 1.5rem;
  margin-top: 5%;
}
.p1-button-group {
  text-align: center;
  margin-top: 10%;
  padding-bottom: 5%;
  padding-right: 2%;
  display: flex;
  justify-content: space-evenly;
}
a {
  text-decoration: none;
}
.p1-button1 {
  background-color: rgba(10, 10, 10, 0);

  box-shadow: 5px 5px 11px -2px #707070;
  width: 55px;
  height: 50px;
  border-radius: 10%;
  color: white;
  padding-top: 15px;
}
.p1-button2 {
  background-color: rgba(10, 10, 10, 0);
  box-shadow: 5px 5px 11px -2px #707070;
  width: 55px;
  height: 50px;
  border-radius: 10%;
  color: white;
  padding-top: 15px;
}
.p1-image {
  width: 480px;
  height: 30vh;
}
h2 {
  color: white;
}
.p2 {
  margin-top: 5%;
}
.Card {
  background-color: black;
}
img.card-img-top {
  height: 180px;
}
div.card-body {
  height: 220px;
}
.p3 {
  margin-top: 5%;
}

.p4 {
  margin-top: 5%;
}
.p5 {
  margin-top: 5%;
}

.button-group {
  display: flex;
  justify-content: space-evenly;
}

/* @media screen and (max-width: 769px){
  .p2{
    margin-top: 5%;
  }
} */

/* @media screen and (max-width: 700px){
  Card{
    width: 2rem
}
} */
